import React, { useState } from 'react';
import cx from 'classnames';
import { useRecoilValue } from 'recoil';
import Image from 'next/image';
import { ASSET_URL, CLOUD_STORAGE_CDN } from '@/models/buildtime-constant';
import { GameCompanyTypes } from '@/models/shared-types';
import { GameIconNewFrame } from '../GameIconNewFrame';
import TemporaryCloseOverlay from '../Common/TemporaryCloseOverlay';
import gameBrandStateAtom from '@/recoils/modules/game-brand';
import packageJson from '../../package.json';
import { isEmpty } from 'lodash';

export type GameIconProps = {
  src: string;
  alt?: string;
  width?: number | string;
  height?: number | string;
  layout?: any;
  priority?: boolean;
  quality?: string;
  campaign?: string;
  isCampaign?: boolean;
  ratioChange?: boolean;
  isNew?: boolean;
  refType?: GameCompanyTypes | string;
  className?: string | null;
  newFrameClassName?: string | null;
  onClick?: () => void;
};

const GameIcon = ({
  src,
  alt = '',
  width = '100%',
  height = '100%',
  layout = 'fixed',
  priority = true,
  quality = '75',
  campaign = '',
  isCampaign = false,
  ratioChange = false,
  isNew = false,
  refType,
  className,
  newFrameClassName,
  onClick = () => { },
}: GameIconProps) => {
  const [error, setError] = useState<boolean>(false);
  const [errorJacket, setErrorJacket] = useState<boolean>(false);
  return (
    <div className={cx('ps-game-icon', className, { 'pe-none': error })} onClick={onClick}>
      {isCampaign && (
        <img
          src={`${CLOUD_STORAGE_CDN}/pigspin-assets/campaigns/${campaign}/icon.png`}
          className="ps-game-icon__cover  ps-game-icon__cover--top-layer"
          alt={`${alt}-top-layer`}
        />
      )}
      <Image
        className="ps-game-icon__cover"
        src={error || isEmpty(src) ? `${ASSET_URL}/logo/animate/logo.webp` : src}
        alt={alt}
        width={width ? parseInt(`${width}`) : undefined}
        height={height ? parseInt(`${height}`) : undefined}
        onError={(e) => setError(true)}
        quality={parseInt(quality)}
        priority={priority}
      />
      {isNew && <GameIconNewFrame width={width} height={height} />}
      {!errorJacket
        && (
        <div className={cx(newFrameClassName, 'ps-game-icon__company-frame')}>
          <Image
            src={`${CLOUD_STORAGE_CDN}/assets/${refType}/jacket.webp?v=${packageJson.version}`}
            layout={layout}
            onError={(e) => setErrorJacket(true)}
            width={width ? parseInt(`${width}`) : undefined}
            height={height ? parseInt(`${height}`) : undefined}
            alt={alt}
          />
        </div>
        )}
    </div>
  );
};

export const withGameProviderCheck = (Component: React.FC<GameIconProps>) => {
  const HocComponent = (props: GameIconProps) => {
    const gameBrandState = useRecoilValue(gameBrandStateAtom);
    const brand = gameBrandState.obj[(props.refType || 'NONE') as GameCompanyTypes];
    const isGameProviderOpen = !brand?.isUnderConstruction;
    if (isGameProviderOpen) {
      return <Component {...props} />;
    }
    return (
      <TemporaryCloseOverlay label={{ textShadow: 'none', size: 'sm' }}>
        <Component {...props} />
      </TemporaryCloseOverlay>
    );
  };
  return HocComponent;
};

export default GameIcon;
