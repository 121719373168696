import { AxiosRequestConfig } from 'axios';

import { BaseApiResponse, IPigSpinFormData } from '@/services/service.type';
import { pigspinApiInstance } from '@/utils/api/AxiosInstance';
import { fetchWithFormData } from '@/utils/api/fetch';

export type LoginRequest = {
  phone: string;
  pin: string;
};

// NOTE : PHP API Layer
export interface ILoginFormData extends IPigSpinFormData {
  Phone: string;
  Pin: string;
}

export enum LOGIN_STATUS {
  SUCCESS = 'Success',
  WRONG_PASSWORD = 'WrongPassword',
  BLOCK = 'Block',
  BLOCK_PIN = 'BlockPIN',
  INACTIVE = 'InActive',
  ERROR = 'Error',
  CLIENT_EXCEPTION = 'CLIENT_EXCEPTION', // Client only
  PHONE_NOT_FOUND = 'PhoneNotFound',
  LINE_ID_NOT_FOUND = 'LineIdNotFound',
}

export type LoginResponse = {
  Status: LOGIN_STATUS;
  cfid: string;
  uid: string;
  Phone: string;
  Message?: string;
  CustomerCode: string;
  CustomerImage?: string;
  CustomerName?: string;
  CustomerNameEN?: string;
  GameToken?: string;
  UserUid?: string;
};

export type AuthData = {
  authorization: string;
  token_type: string;
  customer_code: string;
};

export type LoginResponseResult = BaseApiResponse<AuthData>;

export const requestLogin = (request: LoginRequest): Promise<LoginResponseResult> => {
  const form = {
    username: request.phone,
    password: request.pin,
  };
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/auth/login',
    method: 'POST',
  };
  return fetchWithFormData<LoginResponseResult>(pigspinApiInstance, requestConfig, form);
};

export type LoginByKeygenResponse = BaseApiResponse<{ token: string; }>;
export const requestLoginByKenGen = (request: { keygen: string }): Promise<LoginByKeygenResponse> => {
  const form = {
    keygen: request.keygen,
  };
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/auth/login-with-keygen',
    method: 'POST',
  };
  return fetchWithFormData<LoginByKeygenResponse>(pigspinApiInstance, requestConfig, form);
};

export type LineLoginRequest = {
  id_token: string
};

export const requestLineLogin = (request: LineLoginRequest): Promise<LoginResponseResult> => {
  const form = {
    line_id_token: request.id_token,
  };
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/auth/login-with-line',
    method: 'POST',
  };
  return fetchWithFormData<LoginResponseResult>(pigspinApiInstance, requestConfig, form);
};

export type LinkLineRequest = {
  id_token: string;
  auth_token: string;
};

export const requestLinkLine = (request: LinkLineRequest): Promise<BaseApiResponse<any>> => {
  const form = {
    line_id_token: request.id_token,
  };
  const requestConfig: AxiosRequestConfig = {
    url: '/v1/auth/login-with-line',
    method: 'POST',
  };
  return fetchWithFormData<LoginResponseResult>(pigspinApiInstance, requestConfig, form);
};
