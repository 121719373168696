interface IGameIconNewFrameTypes {
  width: number | string;
  height: number | string;
}

export default function GameIconNewFrame({
  width,
  height,
}: IGameIconNewFrameTypes) {
  return (
    <div
      style={{
        width,
        height,
        fontSize: Number(width?.toString()?.replace(/\D/g, '') || 0) / 7,
      }}
      className="ps-new-game-icon-frame"
      data-testid="game-tag-new"
    >
      <div className="ps-new-game-icon-frame__icon">
        <span>New</span>
      </div>
    </div>
  );
}
