import * as React from 'react';
import styled from 'styled-components';
import { FunctionComponent } from '../type';

const WrapperTemporaryCloseOverlay = styled.div`
  position:  relative;
`;

export const GrayOverlay = styled.div`
  filter: grayscale(1);
  opacity: 0.25;
`;

export type TemporaryCloseLabelStyle = {
  size?: 'sm' | 'md' | 'lg';
  textShadow?: string;
  transform?: string;
};
const WrapperTemporaryCloseLabel = styled.div<TemporaryCloseLabelStyle>`
  position: absolute;
  text-align: center;
  right: 0;
  left: 0;
  top: 50%;
  transform:  ${({ transform }) => transform || 'translateY(-50%)'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme['greyscale-500']};
  font-size: ${({ size }) => {
    if (size === 'sm') return '0.8rem';
    if (size === 'md') return '1rem';
    if (size === 'lg') return '1.5rem';
    return '1rem';
  }};
  text-shadow: ${({ textShadow }) => textShadow || '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff'};
`;

export type ITemporaryCloseOverlayProps = {
  label?: TemporaryCloseLabelStyle;
  text?: string;
};

const TemporaryCloseOverlay: FunctionComponent<ITemporaryCloseOverlayProps> = (props) => {
  return (
    <WrapperTemporaryCloseOverlay>
      <GrayOverlay>
        {props.children}
      </GrayOverlay>
      <WrapperTemporaryCloseLabel {...props.label}>
        {props.text || 'ปิดปรับปรุง'}
      </WrapperTemporaryCloseLabel>
    </WrapperTemporaryCloseOverlay>
  );
};

export default TemporaryCloseOverlay;
