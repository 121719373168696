import { UseMutateAsyncFunction, UseMutationResult, useMutation } from 'react-query';
import {
  LoginResponse, LOGIN_STATUS, requestLogin,
} from '@/services/login';
import { mapToLoginResponse } from '@/mappers/mapToLoginResponse';
import { requestLineLogin } from '@/services/login/login';
import { useAuth } from './useAuth';
import { COOKIES_KEY, useCookies } from '@/utils/cookies';

export type LoginRequest = {
  phoneNumber: string;
  pin: string;
};

export type LoginLineRequest = {
  idToken: string;
};

export type LoginHook = {
  loginData?: LoginResponse;
  mutateLogin: UseMutateAsyncFunction<LoginResponse, unknown, LoginRequest, unknown>;
  callLogin: (request: LoginRequest) => Promise<LoginResponse>;
  callLineLogin: (request: LoginLineRequest) => Promise<LoginResponse>;
};

export const useLogin = (): LoginHook => {
  const { authorize } = useAuth();
  const [, setCookies] = useCookies();
  const callLogin = async (request: LoginRequest): Promise<LoginResponse> => {
    const { phoneNumber, pin } = request;
    let response: LoginResponse = {
      Status: LOGIN_STATUS.CLIENT_EXCEPTION,
      cfid: '',
      uid: '',
      Phone: '',
      CustomerCode: '',
    };
    const newResponse = await requestLogin({
      phone: phoneNumber,
      pin,
    });
    response = mapToLoginResponse(newResponse);
    return response;
  };
  const callLineLogin = async (request: LoginLineRequest): Promise<LoginResponse> => {
    const { idToken } = request;
    let response: LoginResponse = {
      Status: LOGIN_STATUS.CLIENT_EXCEPTION,
      cfid: '',
      uid: '',
      Phone: '',
      CustomerCode: '',
    };
    const newResponse = await requestLineLogin({
      id_token: idToken,
    });
    response = mapToLoginResponse(newResponse);
    return response;
  };

  const {
    mutateAsync: mutateLogin,
    data: loginData,
  } = useMutation({
    mutationFn: async (request: LoginRequest) => {
      const result = await callLogin(request);
      setCookies(COOKIES_KEY.UID, request.phoneNumber);
      return result;
    },
    onSuccess: (response) => {
      authorize({
        cfid: response.cfid,
        uid: response.uid,
        customerCode: response.CustomerCode,
      });
    },
  });

  return {
    loginData,
    mutateLogin,
    callLogin,
    callLineLogin,
  };
};
