import { LoginResponse, LoginResponseResult, LOGIN_STATUS } from '@/services/login/login';
import { decodeAuthToken, JwtTokenData } from '@/utils/jwt';

const mapStatus = (loginResponseResult: LoginResponseResult): LOGIN_STATUS => {
  const { service_code } = loginResponseResult;
  if (service_code === 'PIG-2000') return LOGIN_STATUS.SUCCESS;
  if (service_code === 'PIG-4000') return LOGIN_STATUS.INACTIVE;
  if (service_code === 'PIG-4003') return LOGIN_STATUS.WRONG_PASSWORD;
  if (service_code === 'PIG-4004') return LOGIN_STATUS.BLOCK_PIN;
  if (service_code === 'PIG-4005') return LOGIN_STATUS.BLOCK;
  if (service_code === 'PIG-4015') return LOGIN_STATUS.PHONE_NOT_FOUND;
  if (service_code === 'PIG-4016') return LOGIN_STATUS.LINE_ID_NOT_FOUND;
  return LOGIN_STATUS.ERROR;
};

export const mapToLoginResponse = (loginResponseResult: LoginResponseResult): LoginResponse => {
  const status = mapStatus(loginResponseResult);
  // Only LOGIN_STATUS.SUCCESS is using data
  if (status === LOGIN_STATUS.SUCCESS) {
    const jwtData: JwtTokenData = decodeAuthToken(loginResponseResult.data.authorization);
    const phone = !jwtData.phone_number || jwtData.phone_number === 'None' ? '' : jwtData.phone_number;
    return {
      Status: status,
      cfid: `${loginResponseResult.data.token_type} ${loginResponseResult.data.authorization}`,
      uid: phone,
      Phone: phone,
      CustomerCode: loginResponseResult.data.customer_code || '',
      GameToken: jwtData.game_token,
      UserUid: jwtData.user_uid,
    };
  }
  return {
    Status: status,
    cfid: '',
    uid: '',
    Phone: '',
    CustomerCode: '',
    GameToken: '',
    UserUid: '',
  };
};
